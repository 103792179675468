.accountPopup {
    position: absolute;
    display: none;
    z-index: 999;
    top: 120px;
    right: 120px;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #002f53;
    width: 300px;
    height: auto;
    padding: 20px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    opacity: 0;
}

.accountPopup.show {
    display: flex;
    opacity: 1;
    button{
        text-align: center;
        display: -webkit-box;
    }
}

.accountPopup h2{
    margin: 20px;
}

.accountPopup button {
    min-width: 80%;
    min-height: 45px;
    display: none;
}

