/* Define the animation */
@keyframes slideUpDown {
    0% {
        max-height: 0;
        opacity: 0;
    }
    50% {
        max-height: 250px; /* Adjust the max height as needed */
        opacity: 1;
    }
    100% {
        max-height: 0;
        opacity: 0;
    }
}

.user-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: white;
    text-align: left;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);;
}

.user-card button {
    font-size: small;
}

.options-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: max-height 1s, opacity 1s;
    max-height: 0;
    opacity: 0;
    gap: 0;
}

.options-container.open {
    max-height: 280px; /* Adjust the max height as needed */
    opacity: 1;
    animation: none; /* Remove the animation */
}

