.master{
    display: flex;
}

.child{
    flex-direction: column;
    text-align: center;
}

.list{
    min-width: 300px;
    padding: 5px;
}