html, body{
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
    font-family: Arial, sans-serif;
  	font-weight: 300;
    text-align: center;
}

body{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 345px;
    color: #002f53;
    overflow-x: hidden; /* Prevent horizontal scrollbar */
}
body::-webkit-scrollbar {
    width: 0; /* Hides the scrollbar */
    display: none; /* Hides the scrollbar for older versions of WebKit */
}

main {
    display: flex;
    justify-content: center;
    background-color: white;
    flex: 1;
}
#loginMain {
    min-height: 80vh;
    background-image:
            linear-gradient(
                    to bottom,
                    rgba(0, 47, 83, 0.8),
                    rgba(199, 0, 73, 0.7)),
            url("../img/vrpixel.jpg");
    section{
        min-height: 600px;
        background-color: transparent;
        width: 100%;
        display: flex;
    }
    section div{
        padding: 5%;
        width: 500px;
        height: auto;
        color: white;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid white;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}

.bg{
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4)), url("../img/vr3_fhd.jpg");
}
.transWhiteBg{
    background-color: rgba(255, 255, 255, 0.8);
}
button {
    padding: 10px 20px; /* Add padding for better appearance */
    background-color: #002f53; /* Set button background color */
    border: none; /* Remove default border */
    color: white; /* Set text color */
    border-radius: 5px; /* Add rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    font-size: large;
    margin: 2%;
    transition: background-color 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease; /* Slower transitions */
}

/* Style when hovering over the <button> tag */
button:hover {
    background-color: #c70049; /* Change button background color on hover */
    transform: translateY(-3px); /* Apply a slightly larger vertical shift */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Increase the shadow effect */
}
.minWidth200{ min-width: 200px; }
section {
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 5%;
    display: block;
    background-color: white;
    min-height: 0;
    z-index: 2;
}

section h1{
    font-size: xxx-large;
    font-weight: bold;
    text-transform: uppercase;
    animation: slideUp 1.5s 1;
}

section p{
    font-size: x-large;
    text-align: left;
}

.sec {
    z-index: 1;
    min-height: 500px;
    box-shadow: none;
}

.contentSec{
    display: inline-block;
    padding: 20px;
    margin: 2px;
}

.roomSec {
    flex: 3 1;
    min-height: 600px;
    padding: 20px;
    box-sizing: border-box;
    background: transparent;
}

#rSec {padding: 20px;}

.side-nav {
    flex: 1;
    background-color: #f4f4f4;
    padding: 20px;
    overflow-y: auto;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.19);
    animation: slideRight 1.5s 1;
}

.side-nav::-webkit-scrollbar {
    width: 0; /* Hides the scrollbar */
    display: none; /* Hides the scrollbar for older versions of WebKit */
}

.masterDiv {
    display: flex; /* Display children horizontally */
    justify-content: space-around;
    padding: 0;
}

.childDiv {
    display: flex; /* Children of .master will be displayed horizontally */
    flex-direction: column; /* Children inside .child will be displayed vertically */
    margin: 5%; /* Optional: Add margin between .child elements */
}
/* Add a wrapper around side-nav content to make it fill the height */
.side-nav-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Adjust styles for childDiv within side-nav */
.side-nav .childDiv {
    flex: 1;
}

.fitCont{
    min-width: 70%;
    width: fit-content;
    margin: auto;
}
#sec1, #sec2, #sec3{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.App-logo {
    width: 20%;
    pointer-events: none;
}

.table-container {
    text-align: center;
    padding: 5%;
    border-radius: 10px;
}

.table-row {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.table-cell {
    flex: 1;
    padding: 0 10px;
    word-wrap: break-word;
}

.layout {
    display: grid;
    grid-template-rows: repeat(auto-fit, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 5%;
    animation: slideLeft 1.5s 1;
}

.layout .transWhiteBg{
    transition: 0.5s ease;
    cursor: pointer;
}

.layout .transWhiteBg:hover{
    background-color: rgba(215, 215, 215, 0.9);
}

.user-details,
.layout div{
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.user-details{ margin: 0 20% 0 20%; }
.user-details p{ border-bottom: 1px solid #002f53}

@keyframes slideLeft {
    from{
        transform: translateX(-100%);
    }
    to{
        transform: translateX(0);
    }
}
@keyframes slideRight {
    from{
        transform: translateX(100%);
    }
    to{
        transform: translateX(0);
    }
}

@keyframes slideUp {
    from{
        opacity: 0;
        transform: translateY(-100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 1000px) {
    section { min-height: 0;}
    section h1{ font-size: xx-large; }
    section p { font-size: larger;}
    #sec1, #sec2, #sec3 { background-attachment: scroll; }
    .contentSec, .masterDiv{
        display: flex;
        flex-direction: column;
    }
    .layout{ grid-template-columns: repeat(1, 1fr); }
    .user-details{ margin: 0;}
    #loginMain section div{
        width: 80%;
        margin: 20% 10%;
    }
}