.meeting{
    background-color: #002f53;
    color: white;
    padding: 10px 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
}

.meeting:hover{
    background-color: #c70049;
}
