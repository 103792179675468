html, body{
    margin: 0;
    padding: 0;
}
*
{
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}
.showcase
{
    position: absolute;
    right: 0;
    width: 100%;
    min-height: 80vh;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #111;
    transition: 0.5s;
    z-index: 2;
}

#unloggedFooter{ margin-top: 80vh; }
#unloggedHeader{ background: transparent; }

.showcase video
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
    animation: fade ease-in 1.5s;
}
@keyframes fade {
    0%{
        filter: blur(5px);
        opacity: 0;
    }
    100%{
        filter: blur(0);
        opacity: 0.8;
    }
}
.overlay
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 47, 83, 1), rgba(199, 0, 73, 1));
    mix-blend-mode: overlay;
}
.text
{
    position: relative;
    z-index: 10;
    text-align: left;
    animation: slideIn ease-in 1.5s;
}
@keyframes slideIn {
    0%{
        filter: blur(5px);
        opacity: 0;
        transform: translateX(-100%);
    }
    100%{
        filter: blur(0);
        opacity: 1;
        transform: translateX(0);
    }
}
.text h2
{
    font-size: 5em;
    font-weight: 800;
}
.text h3
{
    font-size: 4em;
    font-weight: 700;
}
.text h2,
.text h3 {
    margin: 0;
    text-align: left;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
}
.text p
{
    font-size: 1.1em;
    color: #fff;
    margin: 20px 0;
    font-weight: 400;
    max-width: 700px;
}
.text a
{
    display: inline-block;
    font-size: 1em;
    background: #fff;
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    margin-top: 10px;
    color: #111;
    letter-spacing: 2px;
    transition: 0.2s;
}
.text a:hover
{
    letter-spacing: 6px;
}
body::-webkit-scrollbar {
    width: 0; /* Hides the scrollbar */
    display: none; /* Hides the scrollbar for older versions of WebKit */
}
@media (max-width: 1920px)
{
    .showcase,
    .showcase header
    {
        padding: 40px;
    }
    .text h2
    {
        font-size: 3em;
    }
    .text h3
    {
        font-size: 2em;
    }
}