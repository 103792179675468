.dots-container {
    display: flex;
    justify-content: center;
}

.dot {
    border-radius: 50%;
    justify-content: center;
    background-color: transparent;
    margin: 5px;
    cursor: pointer;
}