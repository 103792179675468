header {
    top: 0;
    background-color: #002f53;
    color: #ffffff;
    position: sticky;
    z-index: 9;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-height: 105px;
}

header img,
footer img {
    background-color: white;
    padding: 2px;
    border-radius: 10px;
}

header .App-logo,
footer .App-logo{
    width: 80px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#logoSelect{
    height: 80px;
    width: 80px;
    font-size: 80px;
}
header a{
    color: white;
    margin: 10px;
    font-size: 20px;
    text-decoration: none;
    transition: color 0.5s ease;
}
header a:hover{ color: #c70049; }
#header { text-align: right; }
#header-resp { display: none; }
#header-left { text-align: left;}

@media only screen and (max-width: 1000px) {
    #header {
        display: none;
    }

    #header-resp {
        display: inline-block;
        text-align: right;
    }
}