.info{
    opacity: 0;
    position: absolute;
    z-index: 999;
    top: 25%;
    left: 35%;
    width: 400px;
    height: auto;
    align-items: center;
    justify-content: center;
    background: #002f53;
    color: white;
    border-radius: 10px;
    box-shadow: none;
    transition: opacity .2s ease-in-out;
    p{
        font-size: 14px;
        text-align: center;
    }
}

.open{
    opacity: 1;
}

.infoIcon{
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.infoIcon:hover{
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, .6);
}